export interface IToastViewProps {
    header?: string;
    text: string;
    footer?: string;
    autoClose: number;
    position: string;
    onClose?(): void;
    canClose: boolean;
    showProgress: boolean;
    type?: 'success' | 'error' | 'warning' | 'info';
    link?: string;
    linkLabel?: string;
}

export const toast = (props: IToastViewProps) => {
    new Toast(props);
};

export const toasts = {
    success: (header: string | undefined, text: string, props: Partial<IToastViewProps> = {}) => {
        toast({
            ...{
                autoClose: 3000,
                canClose: true,
                position: 'bottom-right',
                type: 'success',
                showProgress: true
            },
            ...props as IToastViewProps,
            header,
            text,
            type: 'success'
        });
    },
    error: (header: string | undefined, text: string, props: Partial<IToastViewProps> = {}) => {
        toast({
            ...{
                autoClose: 30000,
                canClose: true,
                position: 'bottom-right',
                showProgress: true
            },
            ...props as IToastViewProps,
            header,
            text,
            type: 'error'
        });
    }
};

class Toast {

    private readonly _props: IToastViewProps;
    private _toastElem: HTMLElement;
    private _headElem: HTMLElement | undefined;
    private _textElem: HTMLElement;
    private _footElem: HTMLElement | undefined;
    // @ts-ignore
    private _removeBinded(): void;
    // @ts-ignore
    private _unpause(): void;
    // @ts-ignore
    private _pause(): void;
    // @ts-ignore
    private _visibilityChange(): void;
    private _isPaused: boolean = false;
    private _shouldUnPause: boolean = true;
    private _timeVisible: number = 0;
    private _autoCloseInterval: number = 0;
    private _progressInterval: number = 0;

    constructor(input: IToastViewProps) {
        this._props = input;

        this._toastElem = document.createElement("div");
        this._textElem = document.createElement("span");

        if (this._props.header) {
            this._headElem = document.createElement("h4");
            this._headElem.textContent = this._props.header;
            this._toastElem.appendChild(this._headElem);
        } else {
            this._textElem.classList.add("no-header");
        }

        this._toastElem.appendChild(this._textElem);

        if (this._props.link) {
            // Cart button
            const cartButton = document.createElement("a");
            cartButton.classList.add("toast-button");
            cartButton.setAttribute('aria-label', this._props.linkLabel || this._props.link);
            cartButton.textContent = this._props.linkLabel || this._props.link;
            cartButton.href = this._props.link;
            this._toastElem.appendChild(cartButton);
        }

        if (this._props.footer) {
            this._footElem = document.createElement("div");
            this._footElem.classList.add("footer");
            this._footElem.textContent = this._props.footer;
            this._toastElem.appendChild(this._footElem);
        }

        this._toastElem.classList.add("toast");
        this._toastElem.classList.add(this._props.type || 'info');
        requestAnimationFrame(() => {
            this._toastElem.classList.add("show");
        });
        this._removeBinded = this._remove.bind(this);
        this._unpause = () => (this._isPaused = false);
        this._pause = () => (this._isPaused = true);
        this._visibilityChange = () => {
            this._shouldUnPause = document.visibilityState === "visible";
        };
        this._setup();
    }

    private readonly _autoClose = () => {
        if (this._props.autoClose === 0) {
            return;
        }
        this._timeVisible = 0;
        let lastTime: number | null = null;
        const func = (time: number) => {
            if (this._shouldUnPause) {
                lastTime = null;
                this._shouldUnPause = false;
            }
            if (lastTime === null) {
                lastTime = time;
                this._autoCloseInterval = requestAnimationFrame(func);
                return;
            }
            if (!this._isPaused) {
                this._timeVisible += time - lastTime;
                if (this._timeVisible >= this._props.autoClose) {
                    this._remove();
                    return;
                }
            }
            lastTime = time;
            this._autoCloseInterval = requestAnimationFrame(func);
        };
        this._autoCloseInterval = requestAnimationFrame(func);
    };

    private readonly _position = () => {
        const currentContainer = this._toastElem.parentElement;
        const selector = `.toast-container[data-position="${this._props.position}"]`;
        const container = document.querySelector(selector) || this._createContainer(this._props.position);
        container.append(this._toastElem);
        if (currentContainer === null || currentContainer.hasChildNodes()) {
            return;
        }
        currentContainer.remove();
    };

    private readonly _text = () => {
        this._textElem.textContent = this._props.text;
    };

    private readonly _canClose = () => {
        this._toastElem.classList.toggle("can-close", this._props.canClose);
        /* if (this._props.canClose) {
            this._toastElem.addEventListener("click", this._removeBinded);
        } else {
            this._toastElem.removeEventListener("click", this._removeBinded);
        } */

        if (this._props.canClose) {
            const closeButton = document.createElement("button");
            closeButton.classList.add("close-button");
            closeButton.addEventListener("click", this._removeBinded);

            const closeIcon = document.createElement("i");
            closeIcon.classList.add("fa-solid", "fa-xmark");
            closeButton.appendChild(closeIcon);

            this._toastElem.appendChild(closeButton);
        }
    };

    private readonly _showProgress = () => {
        this._toastElem.classList.toggle("progress", this._props.showProgress);
        // @ts-ignore
        this._toastElem.style.setProperty("--progress", 1);

        if (this._props.showProgress) {
            const func = () => {
                if (!this._isPaused) {
                    this._toastElem.style.setProperty(
                        "--progress",
                        // @ts-ignore
                        1 - this._timeVisible / this._props.autoClose
                    );
                }
                this._progressInterval = requestAnimationFrame(func);
            };
            this._progressInterval = requestAnimationFrame(func);
        }
    };

    private readonly _pauseOnHover = () => {
        this._toastElem.addEventListener("mouseenter", this._pause);
        this._toastElem.addEventListener("mouseleave", this._unpause);
    };

    private readonly _setup = () => {
        this._autoClose();
        this._position();
        this._text();
        this._canClose();
        this._showProgress();
        this._pauseOnHover();
    };

    private readonly _remove = () => {
        cancelAnimationFrame(this._autoCloseInterval);
        cancelAnimationFrame(this._progressInterval);
        const container = this._toastElem.parentElement;
        this._toastElem.classList.remove("show");
        this._toastElem.addEventListener("transitionend", () => {
            this._toastElem.remove();
            if (container?.hasChildNodes()) {
                return;
            }
            container?.remove();
        });
        this._props.onClose?.();
    };

    private readonly _createContainer = (position: string): Element => {
        const container = document.createElement("div");
        container.classList.add("toast-container");
        container.dataset.position = position;
        document.body.append(container);
        return container;
    };
}